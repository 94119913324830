/*
 * Copyright 2020 DevIntent - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without the express permission of DevIntent.
 *
 * No warranty, explicit or implicit, provided.
 * In no event shall DevIntent be liable for any claim or damages related to this code.
 */
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { timer } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SwUpdateService {
  private START_CHECK_FOR_UPDATE = 5000; // milliseconds
  private CHECK_FOR_UPDATE_INTERVAL = 1000 * 60 * 60; // 60 minutes

  constructor(private swUpdate: SwUpdate, snackBar: MatSnackBar) {
    swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        mergeMap((event: VersionReadyEvent) => {
          console.log('Current app version is: ', event.currentVersion);
          console.log('Available app version is: ', event.latestVersion);
          return snackBar.open('An update to this site is available.', 'UPDATE NOW', {}).onAction();
        }),
      )
      .subscribe(() => {
        swUpdate.activateUpdate().then(() => document.location.reload());
      });
  }

  checkForUpdate(): void {
    if (this.swUpdate) {
      timer(this.START_CHECK_FOR_UPDATE, this.CHECK_FOR_UPDATE_INTERVAL).subscribe(() => {
        console.log('Checking server for site update...');
        this.swUpdate
          .checkForUpdate()
          .then(() => console.log('Site update check complete.'))
          .catch(error => console.error(`Site update check failed: ${error}`));
      });
    } else {
      setTimeout(this.checkForUpdate, 1000);
    }
  }
}

/*
 * Copyright 2020 DevIntent - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without the express permission of DevIntent.
 *
 * No warranty, explicit or implicit, provided.
 * In no event shall DevIntent be liable for any claim or damages related to this code.
 */
import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import { NavComponent } from './nav/nav.component';
import { SwUpdateService } from './sw-update.service';

@Component({
  selector: 'dev-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [NavComponent],
})
export class AppComponent {
  constructor(private swUpdateService: SwUpdateService) {
    if (environment.serviceWorkerEnabled) {
      this.swUpdateService.checkForUpdate();
    }
  }
}

/*
 * Copyright 2022 DevIntent - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without the express permission of DevIntent.
 *
 * No warranty, explicit or implicit, provided.
 * In no event shall DevIntent be liable for any claim or damages related to this code.
 */
import { NgIf, NgSwitch, NgSwitchCase, NgOptimizedImage, NgSwitchDefault, AsyncPipe } from '@angular/common';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatIconRegistry, MatIcon } from '@angular/material/icon';
import {
  MatNavList,
  MatListItem,
  MatListItemIcon,
  MatListItemTitle,
  MatListSubheaderCssMatStyler,
  MatListItemMeta,
} from '@angular/material/list';
import { MatDrawer, MatSidenavContent, MatSidenavContainer, MatSidenav } from '@angular/material/sidenav';
import { MatToolbar, MatToolbarRow } from '@angular/material/toolbar';
import { MatTooltip } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { take } from 'rxjs/operators';
import { AnalyticsService } from '../analytics.service';
import { ThemeService } from '../core/services/theme.service';
import { FooterComponent } from '../footer/footer.component';
import { NavService } from '../nav.service';
import { ContactUsButtonComponent } from '../shared/contact-us-button/contact-us-button.component';
import { Theme } from '../shared/theme-toggle/theme';

@Component({
  selector: 'dev-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  standalone: true,
  imports: [
    MatSidenavContainer,
    MatSidenav,
    MatToolbar,
    MatToolbarRow,
    MatIconButton,
    MatTooltip,
    MatIcon,
    MatDivider,
    MatNavList,
    MatListItem,
    RouterLink,
    RouterLinkActive,
    MatListItemIcon,
    MatListItemTitle,
    MatListSubheaderCssMatStyler,
    MatListItemMeta,
    MatSidenavContent,
    NgIf,
    NgSwitch,
    NgSwitchCase,
    NgOptimizedImage,
    NgSwitchDefault,
    ContactUsButtonComponent,
    RouterOutlet,
    FooterComponent,
    AsyncPipe,
  ],
})
export class NavComponent implements AfterViewInit {
  @ViewChild('drawer', { static: true }) drawer: MatDrawer;
  @ViewChild('sidenavContent', { static: true }) sidenavContent: MatSidenavContent;
  theme = Theme;

  constructor(
    public navService: NavService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    public readonly analyticsService: AnalyticsService,
    private readonly router: Router,
    public themeService: ThemeService,
  ) {
    iconRegistry.addSvgIcon('github', sanitizer.bypassSecurityTrustResourceUrl('assets/font-awesome/svgs/github.svg'));
    iconRegistry.addSvgIcon('twitter', sanitizer.bypassSecurityTrustResourceUrl('assets/font-awesome/svgs/twitter.svg'));
    iconRegistry.addSvgIcon('youtube', sanitizer.bypassSecurityTrustResourceUrl('assets/font-awesome/svgs/youtube.svg'));
    iconRegistry.addSvgIcon('dev', sanitizer.bypassSecurityTrustResourceUrl('assets/font-awesome/svgs/dev.svg'));
    iconRegistry.addSvgIcon('linkedin', sanitizer.bypassSecurityTrustResourceUrl('assets/font-awesome/svgs/linkedin.svg'));
    iconRegistry.addSvgIcon('medium', sanitizer.bypassSecurityTrustResourceUrl('assets/font-awesome/svgs/medium-m.svg'));
    iconRegistry.addSvgIcon('facebook', sanitizer.bypassSecurityTrustResourceUrl('assets/font-awesome/svgs/facebook-f.svg'));
    iconRegistry.addSvgIcon('npm', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/npm-logo.svg'));
  }

  get route(): string {
    return this.router.url;
  }

  ngAfterViewInit(): void {
    this.navService.drawer = this.drawer;
    this.navService.sidenavContent = this.sidenavContent;
  }

  onNavigation(): void {
    // Check display size so that we don't close a locked open sidenav on larger displays
    this.navService.isSmallOrLess.pipe(take(1)).subscribe((isSmallOrLess: boolean) => {
      // Need to close an 'over' mode sidenav, used on mobile.
      if (isSmallOrLess) {
        this.navService.drawer.close();
      }
    });
  }
}

import { MediaMatcher } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Theme } from 'src/app/shared/theme-toggle/theme';

import { StorageService } from './storage.service';
import { StyleManagerService } from './style-manager.service';

export const THEME_STORAGE_KEY = 'theme';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private readonly prefersDarkThemeQuery = this.mediaMatcher.matchMedia('(prefers-color-scheme: dark)');
  private readonly themeLinkId = 'themeStylesCustom';
  // The name of the styles-(dark|light).css should be kept in sync with index.html > themeStyles
  private readonly stylesFileNames: { dark: string; light: string } = {
    dark: 'styles-dark.css',
    light: 'styles-light.css',
  };

  private isAutoTheme: boolean;
  private currentThemeSubject = new BehaviorSubject<Exclude<Theme, Theme.AUTO> | null>(null);
  public currentTheme = this.currentThemeSubject.asObservable();

  constructor(
    private readonly storageService: StorageService,
    private readonly styleManager: StyleManagerService,
    private readonly mediaMatcher: MediaMatcher,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    this.detectStyleFileNames();

    // Load the theme if exists in the local storage
    this.loadTheme();

    // Watch for changes of the prefers color
    this.prefersDarkThemeQuery.addEventListener('change', ({ matches }) => {
      if (this.isAutoTheme) {
        this.setCurrentTheme(matches ? Theme.DARK : Theme.LIGHT);
      }
    });
  }

  private loadTheme() {
    this.setTheme(this.storageService.getItem(THEME_STORAGE_KEY) ?? Theme.AUTO);
  }

  setTheme(theme: Theme) {
    if (theme === Theme.AUTO) {
      this.isAutoTheme = true;
      this.setAutoTheme();
      // Remove the custom style and initialize based on the prefers-color-scheme
      this.styleManager.removeStyle(this.themeLinkId);
    } else {
      this.isAutoTheme = false;
      this.setCurrentTheme(theme);
      this.styleManager.setStyle(this.themeLinkId, `${theme === Theme.DARK ? this.stylesFileNames.dark : this.stylesFileNames.light}`);
      this.storageService.setItem(THEME_STORAGE_KEY, theme);
    }
  }

  getStoredTheme(): Theme | null {
    return this.storageService.getItem(THEME_STORAGE_KEY);
  }

  private setAutoTheme() {
    this.setCurrentTheme(this.prefersDarkThemeQuery.matches ? Theme.DARK : Theme.LIGHT);
    // The theme auto shouldn't be stored in the local storage
    this.storageService.removeItem(THEME_STORAGE_KEY);
  }

  private setCurrentTheme(theme: Exclude<Theme, Theme.AUTO>) {
    this.currentThemeSubject.next(theme);
  }

  private detectStyleFileNames() {
    const darkStyleElementName = this.document.querySelector('link[rel="stylesheet"][href*="styles-dark"]')?.getAttribute('href');
    const lightStyleElementName = this.document.querySelector('link[rel="stylesheet"][href*="styles-light"]')?.getAttribute('href');

    if (darkStyleElementName && lightStyleElementName) {
      this.stylesFileNames.dark = darkStyleElementName;
      this.stylesFileNames.light = lightStyleElementName;
    } else {
      console.error('The dark or light theme file has not been found');
    }
  }
}

<!--
  ~ Copyright 2020 DevIntent - All Rights Reserved
  ~
  ~ Unauthorized copying of this file, via any medium is strictly prohibited
  ~ without the express permission of DevIntent.
  ~
  ~ No warranty, explicit or implicit, provided.
  ~ In no event shall DevIntent be liable for any claim or damages related to this code.
  -->
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport="true"
    [disableClose]="(navService.isSmallOrLess | async) === false"
    [attr.role]="(navService.isSmallOrLess | async) ? 'dialog' : 'navigation'"
    [mode]="(navService.isSmallOrLess | async) ? 'over' : 'side'"
    [opened]="(navService.isSmallOrLess | async) === false"
  >
    <mat-toolbar class="sidenav-toolbar">
      <mat-toolbar-row>
        <span id="sidenavTitle">Navigation</span>
        <button
          id="skipToMainButton"
          mat-icon-button
          (click)="navService.focusSidenavContent()"
          aria-label="Skip to main content"
          matTooltip="Focus main content header"
        >
          <mat-icon>chevron_right</mat-icon>
        </button>
      </mat-toolbar-row>
    </mat-toolbar>
    <mat-divider></mat-divider>
    <mat-nav-list (click)="onNavigation()">
      <a mat-list-item routerLink="" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" id="aboutMenuItem">
        <mat-icon matListItemIcon>home</mat-icon>
        <h4 matListItemTitle>Home</h4>
      </a>
      <a mat-list-item routerLink="team" routerLinkActive="active" id="teamMenuItem">
        <mat-icon matListItemIcon>people</mat-icon>
        <h4 matListItemTitle>Team</h4>
      </a>
      <a mat-list-item routerLink="careers" routerLinkActive="active">
        <mat-icon matListItemIcon>work</mat-icon>
        <h4 matListItemTitle>Careers</h4>
      </a>
      <a mat-list-item routerLink="blog" routerLinkActive="active" id="blogMenuItem">
        <mat-icon matListItemIcon>description</mat-icon>
        <h4 matListItemTitle>Blog</h4>
      </a>
      <a
        mat-list-item
        (click)="analyticsService.contactFormOpened(route)"
        routerLink="contact-us"
        routerLinkActive="active"
        id="contactUsItem"
      >
        <mat-icon matListItemIcon>contact_page</mat-icon>
        <h4 matListItemTitle>Contact Us</h4>
      </a>
    </mat-nav-list>
    <mat-divider></mat-divider>
    <mat-nav-list dense>
      <h3 matSubheader>External Links</h3>
      <a mat-list-item href="https://www.linkedin.com/company/devintent-llc" target="_blank" rel="noopener">
        <mat-icon matListItemIcon svgIcon="linkedin"></mat-icon>
        <h4 matListItemTitle>LinkedIn</h4>
        <span matListItemMeta>
          <mat-icon class="external-link">launch</mat-icon>
        </span>
      </a>
      <a mat-list-item href="https://github.com/devintent" target="_blank" rel="noopener">
        <mat-icon matListItemIcon svgIcon="github"></mat-icon>
        <h4 matListItemTitle>GitHub</h4>
        <span matListItemMeta>
          <mat-icon class="external-link">launch</mat-icon>
        </span>
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content #sidenavContent>
    <mat-toolbar class="header mat-elevation-z1" role="banner">
      <button
        id="drawer-toggle"
        type="button"
        aria-label="Toggle side nav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="navService.isSmallOrLess | async"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span id="toolbar-logo">
        <a routerLink="">
          <ng-container [ngSwitch]="themeService.currentTheme | async">
            <img
              *ngSwitchCase="theme.LIGHT"
              class="devintent-logo"
              ngSrc="../../assets/devintent-logo.svg"
              height="41"
              width="146"
              alt="DevIntent Logo"
              priority
            />
            <img
              *ngSwitchDefault
              class="devintent-logo"
              ngSrc="../../assets/devintent-logo-white.svg"
              height="41"
              width="146"
              alt="DevIntent Logo"
              priority
            />
          </ng-container>
        </a>
      </span>
      <dev-contact-us label="CONTACT" cssClass="contact-button" [route]="route">
        <mat-icon>contact_page</mat-icon>
      </dev-contact-us>
    </mat-toolbar>
    <router-outlet></router-outlet>
    <dev-footer></dev-footer>
  </mat-sidenav-content>
</mat-sidenav-container>

<!--
  ~ Copyright 2022 DevIntent - All Rights Reserved
  ~
  ~ Unauthorized copying of this file, via any medium is strictly prohibited
  ~ without the express permission of DevIntent.
  ~
  ~ No warranty, explicit or implicit, provided.
  ~ In no event shall DevIntent be liable for any claim or damages related to this code.
  -->
<footer>
  <div class="footer-container">
    <div class="section company">
      <div class="logo">
        <a routerLink="">
          <ng-container [ngSwitch]="themeService.currentTheme | async">
            <img
              *ngSwitchCase="theme.LIGHT"
              class="devintent-logo"
              src="assets/devintent-logo.svg"
              height="52"
              width="239"
              alt="DevIntent Logo"
            />
            <img
              *ngSwitchDefault
              class="devintent-logo"
              src="assets/devintent-logo-white.svg"
              height="52"
              width="239"
              alt="DevIntent Logo"
            />
          </ng-container>
        </a>
      </div>
      <div class="company-description">
        <p>
          DevIntent is a Florida-based company helping innovators deliver web-focused software outcomes that drive and scale their
          businesses.
        </p>
      </div>
      <dev-theme-toggle></dev-theme-toggle>
    </div>
    <div class="section links">
      <div id="local-links">
        <h4 class="mat-subtitle-2">Links</h4>
        <ul>
          <li><a routerLink="">Home</a></li>
          <li><a routerLink="team">Team</a></li>
          <li><a routerLink="careers">Careers</a></li>
          <li><a routerLink="blog">Blog</a></li>
          <li><a (click)="analyticsService.contactFormOpened(route)" routerLink="contact-us" id="contact-link">Contact</a></li>
        </ul>
      </div>
      <div id="social-links">
        <h4 class="mat-subtitle-2">Social</h4>
        <ul>
          <li>
            <a href="https://github.com/devintent" target="_blank" rel="noopener"
              ><mat-icon svgIcon="github" id="github-icon"></mat-icon>GitHub</a
            >
          </li>
          <li>
            <a href="https://www.linkedin.com/company/devintent-llc" target="_blank" rel="noopener"
              ><mat-icon svgIcon="linkedin" id="linkedin-icon">linkedin</mat-icon>LinkedIn</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="copyright-privacy">
    <p>Content and Design &copy; DevIntent {{ currentYear }}. All Rights Reserved.&nbsp;</p>
    <p id="disclaimer">Technology and Tools product icons are the property of their respective owners.</p>
  </div>
</footer>

/*
 * Copyright 2022 DevIntent - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without the express permission of DevIntent.
 *
 * No warranty, explicit or implicit, provided.
 * In no event shall DevIntent be liable for any claim or damages related to this code.
 */
import { NgSwitch, NgSwitchCase, NgSwitchDefault, AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';
import { AnalyticsService } from '../analytics.service';
import { ThemeService } from '../core/services/theme.service';
import { Theme } from '../shared/theme-toggle/theme';
import { ThemeToggleComponent } from '../shared/theme-toggle/theme-toggle.component';

@Component({
  selector: 'dev-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [RouterLink, NgSwitch, NgSwitchCase, NgSwitchDefault, ThemeToggleComponent, MatIcon, AsyncPipe],
})
export class FooterComponent {
  public currentYear: number = new Date().getFullYear();
  theme = Theme;

  constructor(public readonly analyticsService: AnalyticsService, private readonly router: Router, public themeService: ThemeService) {}

  get route(): string {
    return this.router.url;
  }
}
